import { Route, Routes } from 'react-router-dom'
import Particles, { initParticlesEngine } from '@tsparticles/react'
import { useEffect, useMemo, useState } from 'react'
import { loadSlim } from '@tsparticles/slim'
import Home from './components/Home/home'
import About from './components/About/about'
import Contact from './components/Contact/contact'
import Portfolio from './components/Portfolio/portfolio'
import Layout from './components/Layout/layout'
import consts from './consts.scss'
import './App.scss'

const App = () => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine)
    }).then(() => {
      setInit(true)
    })
  }, [])

  const options = useMemo(
    () => ({
      fpsLimit: 60,
      interactivity: {
        events: {
          onHover: {
            enable: true,
            mode: 'grab',
          },
        },
      },
      particles: {
        color: {
          value: consts.secondary,
        },
        links: {
          color: consts.primaryColor,
          distance: 100,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 4,
        },
        number: {
          density: {
            enable: true,
          },
          value: 5,
        },
        opacity: {
          value: 0.2,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
    }),
    []
  )

  if (init) {
    return (
      <>
        <Particles id="tsparticles" options={options} />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
        </Routes>
      </>
    )
  }

  return <></>
}

export default App
