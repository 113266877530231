import React, { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import AnimatedLetters from '../AnimatedLetters/animatedLetters';
import { useTranslation } from 'react-i18next';
import Logo from '../Home/Logo/logo';
import './contact.scss';
import Loader from 'react-loaders';

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [messageSent, setMessageSent] = useState(false);
  const [captchaCorrect, setCaptchaCorrect] = useState(false);
  const [captchaItems, setCaptchaItems] = useState([]);
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const { t, i18n } = useTranslation();
  const form = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);

    const items = ['chat', 'chien', 'oiseau', 'voiture'];
    const answer = 'voiture';
    setCaptchaItems(items);
    setCaptchaAnswer(answer);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    if (!captchaCorrect) {
      alert(t('contact.captcha_error'));
      return;
    }

    emailjs.sendForm('service_9dqxxfp', 'template_gw4ai4s', form.current, 'veBs93Ua2-COwz_AY')
      .then(() => {
        setMessageSent(true);
        for (let index = 0; index < form.current.length - 1; index++) {
          form.current[index].value = '';
        }
      }, () => {
        setMessageSent(false);
      });
  };

  const handleCaptchaChange = (e) => {
    setCaptchaCorrect(e.target.value === captchaAnswer);
  };

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            {i18n.resolvedLanguage === 'fr' ? (
              <AnimatedLetters
                letterClass={letterClass}
                strArray={['C', 'o', 'n', 't', 'a', 'c', 't', 'e', 'z', '', 'm', 'o', 'i']}
                idx={15}
              />
            ) : (
              <AnimatedLetters letterClass={letterClass} strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']} idx={15} />
            )}
          </h1>
          <p>{t('contact.p-1')}</p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder={t('contact.name')} type="text" name="name" disabled={messageSent} required />
                </li>
                <li className="half">
                  <input placeholder={t('contact.email')} type="email" name="email" disabled={messageSent} required />
                </li>
                <li>
                  <input placeholder={t('contact.subject')} type="text" name="subject" required disabled={messageSent} />
                </li>
                <li>
                  <textarea
                    placeholder={t('contact.message')}
                    name="message"
                    style={{ minWidth: '100%', maxHeight: '300px' }}
                    disabled={messageSent}
                    required
                  ></textarea>
                </li>
                <li className="captcha-container">
                  <label className="captcha-label">{t('contact.find_intruder')}</label>
                  <select className="captcha-select" onChange={handleCaptchaChange}>
                    {captchaItems.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </li>
                <li>
                  <input
                    type="submit"
                    className="flat-button"
                    disabled={messageSent || !captchaCorrect}
                    value={messageSent ? t('contact.sent') : t('contact.send')}
                  />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <Logo />
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Contact;
